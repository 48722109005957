/**
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
import {
    animate,
    motion,
    useInView,
    useMotionValue,
    useTransform,
} from "framer-motion"
import { useEffect, useRef } from "react"
import { addPropertyControls, ControlType } from "framer"

type CounterProps = {
    from: number
    to: number
    duration: number
    fontSize: number
    color: string
    decimalPlaces: number
    prefix: string
    suffix: string
}

function AnimatedCounter({
    from,
    to,
    duration,
    fontSize,
    color,
    decimalPlaces,
    prefix,
    suffix,
}: CounterProps) {
    const count = useMotionValue(from)
    const formatted = useTransform(count, (latest) => {
        const roundedValue = parseFloat(latest.toFixed(decimalPlaces))
        return `${prefix}${roundedValue}${suffix}`
    })
    const ref = useRef(null)
    const inView = useInView(ref)

    // while in view animate the count
    useEffect(() => {
        if (inView) {
            animate(count, to, { duration, ease: "easeOut" })
        }
    }, [count, inView, to, duration])

    return (
        <motion.span
            ref={ref}
            style={{
                fontFamily: "Poppins, sans-serif",
                fontSize,
                color,
                fontWeight: "bold",
            }}
        >
            {formatted}
        </motion.span>
    )
}

AnimatedCounter.defaultProps = {
    from: 0,
    to: 15, // Change to the desired end value with decimals
    duration: 2.5,
    fontSize: 48,
    color: "#5514B4",
    decimalPlaces: 1, // Specify the number of decimal places
    prefix: "$", // Specify the prefix (can be empty)
    suffix: "", // Specify the suffix (can be empty)
}

addPropertyControls(AnimatedCounter, {
    from: {
        type: ControlType.Number,
        title: "From",
        defaultValue: 0,
        min: 0,
        step: 0.1, // Adjust the step to your preference
    },
    to: {
        type: ControlType.Number,
        title: "To",
        defaultValue: 15, // Change to the desired end value with decimals
        min: 0,
        step: 0.1, // Adjust the step to your preference
    },
    duration: {
        type: ControlType.Number,
        title: "Duration",
        defaultValue: 2.5,
        min: 0,
        step: 0.1,
        displayStepper: true,
    },
    fontSize: {
        type: ControlType.Number,
        title: "Font Size",
        defaultValue: 48,
        min: 12,
        max: 72,
        step: 1,
    },
    color: {
        type: ControlType.Color,
        title: "Color",
        defaultValue: "#5514B4",
    },
    decimalPlaces: {
        type: ControlType.Number,
        title: "Decimal Places",
        defaultValue: 1, // Specify the default number of decimal places
        min: 0,
        step: 1,
    },
    prefix: {
        type: ControlType.String,
        title: "Prefix",
        defaultValue: "$", // Specify the default prefix (can be empty)
    },
    suffix: {
        type: ControlType.String,
        title: "Suffix",
        defaultValue: "", // Specify the default suffix (can be empty)
    },
})

export default AnimatedCounter
